<template>
  <div class="desktop-layout">
    <div class="desktop-layout__wrap">
      <Header />
      <MobileHeader />
      <slot />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header/Header'
import Footer from '@/components/Footer/Footer'
import MobileHeader from '@/components/Header/MobileHeader'

export default {
  name: 'DesktopLayout',
  components: {
    MobileHeader,
    Footer,
    Header
  }
}
</script>

<style lang="scss">
.desktop-layout {
  height: 100vh;
  overflow-y: auto;

  &__wrap {
    padding-top: map-get($desktopLayout, "HeaderHeight");
    max-width: map-get($desktopLayout, "MainWidth");
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
  }
  .mobile-header {
    display: none;
  }
  @media screen and (min-width: map-get($tabletLayout, "MinWidth")) and (max-width: map-get($tabletLayout, "MaxWidth")) {
    &__wrap {
      padding-top: map-get($tabletLayout, "HeaderHeight");
      max-width: map-get($tabletLayout, "MainWidth");
    }
    @media screen and (max-width: 1100px) {
      .mobile-header {
        display: flex;
      }
      .header {
        display: none;
      }
    }
  }
}
</style>
