<template>
  <header
    class="header"
  >
    <div
      class="header__logo"
      @click="goHome"
    >
      <Icon
        name="logo"
        :width="'41px'"
        :height="'40px'"
      />
      <Icon
        name="logo-title"
      />
    </div>
    <div class="header__menu">
      <Button
        v-for="item in routes"
        :key="item.name"
        :type="activeRoute === item.name ? 'primary' : 'white'"
        class="header__menu__link"
        @click="linkClick(item)"
      >
        <Text weight="700">{{ item.title }}</Text>
      </Button>
      <div class="header__menu__divider" />
      <Button
        class="header__menu__contact"
        @click="onContactSupport"
        @mouseover="toggleShowEmail(true)"
        @mouseleave="toggleShowEmail(false)"
      >
        <div class="header__menu__contact-body">
          <Icon v-show="!isShowEmail" name="mail" />
          <Text weight="700" :ml="isShowEmail ? '' : '12px'">{{ isShowEmail ? contactEmail : 'Contact' }}</Text>
        </div>
      </Button>
    </div>
    <HeaderMenu />
  </header>
</template>

<script>
import Icon from '@/components/UIKit/Icon'
import Button from '@/components/UIKit/Button'
import Text from '@/components/UIKit/Text/Text'
import { useHeader } from '@/components/Header/useHeader'
import { useBreakpointsMedia } from '@/plugins/breakpoints'
import HeaderMenu from '@/components/Header/HeaderMenu'
import { useGoHome } from '@/utils/useGoHome'

export default {
  name: 'Header',
  components: {
    HeaderMenu,
    Text,
    Button,
    Icon
  },
  setup () {
    const {
      routes,
      breakpoints,
      isShowEmail,
      activeRoute,
      contactEmail,
      linkClick,
      toggleShowEmail,
      onContactSupport
    } = useHeader()
    const {
      goHome
    } = useGoHome()
    const {
      isDesktop,
      isTablet
    } = useBreakpointsMedia()

    return {
      routes,
      isTablet,
      isDesktop,
      breakpoints,
      isShowEmail,
      activeRoute,
      contactEmail,
      goHome,
      linkClick,
      toggleShowEmail,
      onContactSupport
    }
  }
}
</script>

<style lang="scss">
.header {
  position: fixed;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: map-get($desktopLayout, "MainWidth");
  width: calc(100%);
  height: map-get($desktopLayout, "HeaderHeight");
  border-bottom: 4px solid black;
  background-color: #fff;

  &__menu {
    display: flex;
    align-items: center;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
    }
    &__link {
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      margin-left: 20px;
      padding: 0 25px !important;

      &:hover {
        background-color: #F2F2F2;
      }
      .text {
        display: block;
        padding-top: 4px;
      }
    }
    &__divider {
      height: 28px;
      width: 1px;
      background-color: #000000;
      opacity: 0.1;
      margin: 0 26px;
    }
    .header__menu__contact {
      width: 146px;
      margin-right: 33px;
      padding: 4px 0 0 0;
      font-size: 16px;
      line-height: 18px;

      &-body {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .icon {
        margin-top: -4px;
      }
    }
  }
  &__logo {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 55px;
    cursor: pointer;

    .icon:first-of-type {
      margin-right: 22px;
    }
  }
  .header-menu {
    display: none;
  }
}
@media screen and (min-width: map-get($tabletLayout, "MinWidth")) and (max-width: map-get($tabletLayout, "MaxWidth")) {
  .header {
    max-width: 1280px;
  }
}
</style>
